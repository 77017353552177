import React, { useRef, useEffect, ReactNode } from 'react';
import { Polyline, PolylineProps, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-polylinedecorator';
import { getPathColor } from '../utils/utils';
import { FlightPath } from '../../../types/generated';

interface Props extends PolylineProps {
  children: ReactNode;
  path: FlightPath;
}

const getPatterns = color => ([
  {
    offset: '50%',
    repeat: 0,
    // @ts-ignore
    symbol: L.Symbol.arrowHead({
      pixelSize: 14,
      polygon: false,
      pathOptions: { stroke: true, color, weight: 4 },
    }),
  },
]);

const MapPolyline = withLeaflet((props: Props) => {
  const { children, path } = props;
  const polyRef = useRef(null);
  const color = getPathColor(path);
  const patterns = getPatterns(color);

  useEffect(() => {
    const polyline = polyRef.current.leafletElement;
    const { map } = polyRef.current.props.leaflet;

    // @ts-ignore
    const layer = L.polylineDecorator(polyline, { patterns }).addTo(map);
    return () => {
      map.removeLayer(layer);
    };
  }, []);

  return (
    <Polyline ref={polyRef} color={color} {...props}>
      {children}
    </Polyline>
  );
});

export default MapPolyline;

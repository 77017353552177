import React, { RefObject, useContext } from 'react';
import { Popup } from 'react-leaflet';
import styled, { css } from 'styled-components';
import { em } from 'polished';
import { getPointColor, getPointFontColor } from '../utils/utils';
import Button from '../../common/components/Button';
import { FormContext } from '../../common/context/form-context';
import { getQueryParams } from '../../common/utils/location';
import { useLocation } from '../../common/hooks';
import { FlightPoint } from '../../../types/generated';

const StyledMapPopup = styled<any>(Popup)`
  .active {
    cursor: pointer;

    &:hover {
      filter: grayscale(0.8) opacity(0.7);
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    margin-top: ${em(6)};
  }

  button {
    padding: 2px 8px;
    font-size: 1em;
    flex: 1;
    white-space: nowrap;
  }

 .leaflet-popup-content {
    margin: 10px 20px;
  }

  .leaflet-popup-content-wrapper {
    color: white;
    font-family: 'Bitter', serif;
    font-size: 1em;
    text-align: center;
    padding: ${em(2)};
    max-width: 180px;
  }

  ${({ point }: any) => css`
    .leaflet-popup-content-wrapper {
      background-color: ${getPointColor(point)};
      color: ${getPointFontColor(point)};
    }

    .leaflet-popup-tip {
      background-color: ${getPointColor(point)};
    }
  `}
`;

interface Props {
  innerRef?: RefObject<HTMLInputElement>;
  point: FlightPoint;
  onClosePopup: (Location) => void;
  onClickLocation: (Location) => void;
  showActions: boolean;
}

export default function MapPopup(props: Props) {
  const {
    innerRef, point, onClickLocation, onClosePopup, showActions,
  } = props;
  const { dispatch } = useContext(FormContext);
  const { location: { search } } = useLocation();
  const state = getQueryParams(search);
  const { faction, fromLoc, toLoc } = state;

  function handleOnClose() {
    onClosePopup(point.location);
  }

  function handleOnClickLocation() {
    onClickLocation(point.location);
  }

  function navigate(query) {
    handleOnClose();
    setTimeout(() => {
      dispatch({ type: 'update', query });
      dispatch({ type: 'trigger' });
    });
  }

  function handleFrom() {
    const query = getQueryParams(search);
    query.fromLoc = point.location;
    navigate(query);
  }

  function handleTo() {
    const query = getQueryParams(search);
    query.toLoc = point.location;
    navigate(query);
  }

  const showFull = showActions && (faction === point.faction || point.faction === 'both');
  return (
    <StyledMapPopup className={point.faction} point={point} onClose={handleOnClose} ref={innerRef}>
      <div className={showFull ? 'active' : ''} onClick={handleOnClickLocation}>{point.location}</div>
      {
        showFull && (
          <div className="actions">
            {
              point.location !== toLoc && (
                <Button onClick={handleFrom}>Go From</Button>
              )
            }
            {
              point.location !== fromLoc && (
                <Button onClick={handleTo}>Go To</Button>
              )
            }
          </div>
        )
      }
    </StyledMapPopup>
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import Checkbox1 from 'rc-checkbox';
import styled from 'styled-components';
import { em } from 'polished';
import 'rc-checkbox/assets/index.css';

const StyledCheckbox = styled.label`
  cursor: pointer;

  .label {
    padding-left: ${em(10)};
  }
`;

interface Props {
  label: string;
  isChecked: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
  name?: string;
}

export default function Checkbox(props: Props) {
  const {
    isChecked, label, onChange, name = 'checkboxName',
  } = props;

  function handleOnChange() {
    onChange(!isChecked);
  }

  return (
    <StyledCheckbox>
      <Checkbox1
        name={name}
        checked={!!isChecked}
        onChange={handleOnChange}
      />
      <span className="label">{label}</span>
    </StyledCheckbox>
  );
}

import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { withLeaflet, MapLayer, ContextProps } from 'react-leaflet';
import L, { DomEvent } from 'leaflet';

interface Props extends ContextProps {
  children: ReactNode;
  position: 'bottomleft' | 'topright';
}

class MapControls extends MapLayer<Props> {
  private panelDiv: any;

  public componentDidMount() {
    const { map } = this.props.leaflet;
    this.leafletElement.addTo(map);
  }

  // @ts-ignore
  public createLeafletElement() {
    const MapInfo = L.Control.extend({
      onAdd: () => {
        this.panelDiv = L.DomUtil.create('div', 'settings');
        DomEvent.disableClickPropagation(this.panelDiv);
        this.setState({});
        return this.panelDiv;
      },
    });
    // @ts-ignore
    return new MapInfo({ position: this.props.position });
  }

  public render() {
    if (!this.panelDiv) {
      return null;
    }
    return createPortal(<>{this.props.children}</>, this.panelDiv);
  }
}

export default withLeaflet(MapControls);

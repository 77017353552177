import { uniqWith } from 'lodash';
import isEqual from 'react-fast-compare';
import { rgba } from 'polished';
import {
  faDove, faMagic, faShip, faTram, faWind, faCookie, faQuestionCircle, faWalking,
} from '@fortawesome/free-solid-svg-icons';
import { RouteItem } from '../components/Routes';
import {
  Faction, FlightClass, FlightPath, FlightPoint, FlightType,
} from '../../../types/generated';

type OPACITY = 'light' | 'dark' | 'base';

const OPACITIES: Record<OPACITY, number> = {
  light: 0.45,
  dark: 0.75,
  base: 1,
};

const COLORS: Record<Faction | FlightType | FlightClass, string> = {
  alliance: '#70b8e8',
  horde: '#d11c00',
  both: '#f4d85d',
  dungeon: 'forestgreen',
  raid: '#373737',
  city: '#f4d85d',
  druid: '#FF7D0A',
};

export function getPointColor(point: FlightPoint, opacity: OPACITY = 'base') {
  let color = COLORS[point.faction];
  if (point.class === 'druid') color = COLORS.druid;
  if (point.type === 'dungeon') color = COLORS.dungeon;
  if (point.type === 'raid') color = COLORS.raid;
  return rgba(color, OPACITIES[opacity]);
}

export function getPointFontColor(point: FlightPoint) {
  switch (true) {
    case point.type === 'dungeon': return 'white';
    case point.type === 'raid': return 'white';
    case point.faction === 'horde': return 'white';
    default: return 'black';
  }
}

export function getPathColor(path: FlightPath) {
  if (path.transport === 'travel' && !path.label) return COLORS.both;
  if (path.class === 'druid') return COLORS.druid;
  return COLORS[path.faction];
}

export function getCoord(loc: string) {
  return loc.split(':').map((val) => parseFloat(val));
}

const LEFT_X_START = -0.044;
const LEFT_X_END = 0.49;
const getLeftOffset = (imageWidth) => imageWidth * LEFT_X_START;
const getLeftRemaining = (imageWidth) => (imageWidth * LEFT_X_END) - getLeftOffset(imageWidth);

const RIGHT_X_START = 0.448;
const RIGHT_X_END = 1.024;
const getRightOffset = (imageWidth) => imageWidth * RIGHT_X_START;
const getRightRemaining = (imageWidth) => (imageWidth * RIGHT_X_END) - getRightOffset(imageWidth);

export function getCoords(flightPoint: FlightPoint, imageWidth, imageHeight) {
  const [x, y] = getCoord(flightPoint.globalLoc);
  const isLeft = flightPoint.continent === 1;
  if (isLeft) {
    const LEFT_OFFSET = getLeftOffset(imageWidth);
    const LEFT_REMAINING = getLeftRemaining(imageWidth);
    return {
      x: (x * LEFT_REMAINING) + LEFT_OFFSET,
      y: (y * imageHeight * 0.8) + (imageHeight * 0.115),
    };
  }
  const RIGHT_OFFSET = getRightOffset(imageWidth);
  const RIGHT_REMAINING = getRightRemaining(imageWidth);
  return {
    x: (x * RIGHT_REMAINING) + RIGHT_OFFSET,
    y: (y * imageHeight * 0.875) + (imageHeight * 0.01),
  };
}

export function getLocation(x, y, imageWidth, imageHeight) {
  const isLeft = x < (imageWidth / 2);
  if (isLeft) {
    const LEFT_OFFSET = getLeftOffset(imageWidth);
    const LEFT_REMAINING = getLeftRemaining(imageWidth);
    return {
      x: (x - LEFT_OFFSET) / LEFT_REMAINING,
      y: (y - imageHeight * 0.115) / (imageHeight * 0.8),
    };
  }
  const RIGHT_OFFSET = getRightOffset(imageWidth);
  const RIGHT_REMAINING = getRightRemaining(imageWidth);
  return {
    x: (x - RIGHT_OFFSET) / RIGHT_REMAINING,
    y: (y - (imageHeight * 0.01)) / (0.875 * imageHeight),
  };
}

export function getPoints(points: FlightPoint[], faction: Faction, withBoth = true) {
  return points.filter((point) => point.faction === faction || (withBoth && point.faction === 'both'));
}

export function getTransportIcon(transport: string) {
  switch (transport) {
    case 'travel': return faWalking;
    case 'item': return faCookie;
    case 'flight': return faDove;
    case 'quest': return faQuestionCircle;
    case 'ship': return faShip;
    case 'spell': return faMagic;
    case 'tram': return faTram;
    case 'zeppelin': return faWind;
    default: return faDove;
  }
}

export function cleanRoutes(route: RouteItem[]) {
  return uniqWith(route, isEqual);
}

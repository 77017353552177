import React, { useEffect, useRef, useState } from 'react';
import { Marker, MarkerProps } from 'react-leaflet';
import { LeafletMouseEvent } from 'leaflet';
import { createIcon } from './PointIcon';
import MapPopup from './MapPopup';
import FormProvider from '../../common/context/form-provider';
import { FlightPoint } from '../../../types/generated';

interface Props extends MarkerProps {
  onClickLocation: (Location) => void;
  point: FlightPoint;
  showPopup: boolean;
}

export default function PointMarker(props: Props) {
  const { onClickLocation, point, showPopup = false } = props;
  const markerRef = useRef(null);
  const popupRef = useRef(null);
  const stickyRef = useRef(false);
  const [showActions, setShowActions] = useState(false);

  // handle popup events
  const onClosePopup = () => {
    if (stickyRef.current && markerRef.current) {
      stickyRef.current = false;
      setShowActions(false);
      markerRef.current.leafletElement.closePopup();
    }
  };
  const onMouseOver = ({ target }: LeafletMouseEvent) => {
    if (!stickyRef.current) {
      target.openPopup();
    }
  };
  const onMouseOut = ({ target }: LeafletMouseEvent) => {
    if (!stickyRef.current) {
      target.closePopup();
    }
  };
  const onClick = ({ target }: LeafletMouseEvent) => {
    stickyRef.current = true;
    target.openPopup();
    setShowActions(true);
  };

  // handle popup
  useEffect(() => {
    if (popupRef.current && popupRef.current.leafletElement) {
      popupRef.current.leafletElement.options.autoClose = false;
      popupRef.current.leafletElement.options.closeOnClick = true;
    }
  }, [popupRef]);

  // handle marker
  useEffect(() => {
    const ref = markerRef.current;
    if (ref && ref.leafletElement) {
      if (showPopup) {
        ref.leafletElement.openPopup();
      } else {
        ref.leafletElement.closePopup();
      }
    }
  }, [showPopup]);

  return (
    <Marker
      ref={markerRef}
      icon={createIcon(point)}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      {...props}
    >
      <FormProvider>
        <MapPopup
          point={point}
          onClosePopup={onClosePopup}
          onClickLocation={onClickLocation}
          innerRef={popupRef}
          showActions={showActions}
        />
      </FormProvider>
    </Marker>
  );
}

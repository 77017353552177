import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { em } from 'polished';
import ButtonIcon from '../../common/components/ButtonIcon';
import Button from '../../common/components/Button';
import Checkbox from '../../common/components/Checkbox';
import { FlightMasterContext } from '../context/flightmaster-context';

const StyledMapSettings = styled.div`
  .icon {
    font-size: 2.5em;
    color: white;
    
    &:hover {
      color: var(--secondaryLight-color)
    }
  }
`;

const StyledMapForm = styled.div`
  display: grid;
  grid-gap: ${em(0)};
  grid-auto-rows: 1fr;
  background-color: var(--secondaryDarkAlpha-color);
  font-size: 1.1em;
  padding: ${em(20)};
  
  label {
    color: white;
  }
`;

export default function MapSettings() {
  const context = useContext(FlightMasterContext);
  const [open, setOpen] = useState(false);

  // return false if no FlightMasterContext
  if (!context) {
    return <></>;
  }

  const { setShowAllPoints, showAllPoints } = context;
  return (
    <StyledMapSettings>
      {
        open ? (
          <StyledMapForm>
            <Checkbox label="Show All Points" isChecked={showAllPoints} onChange={setShowAllPoints} />
            <Button onClick={() => setOpen(false)}>Close</Button>
          </StyledMapForm>
        ) : (
          <ButtonIcon onClick={() => setOpen(true)} label="settings">
            <FontAwesomeIcon icon={faCog} className="icon" />
          </ButtonIcon>
        )
      }
    </StyledMapSettings>
  );
}

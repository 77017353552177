import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import React from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPointColor } from '../utils/utils';
import { FlightPoint } from '../../../types/generated';

function renderIcon(point: FlightPoint) {
  const color = getPointColor(point);
  return renderToStaticMarkup(
    <FontAwesomeIcon
      icon={faCircle}
      style={{
        fontSize: point.type === 'city' ? '1.7em' : '1.4em',
        color,
        stroke: 'black',
        strokeWidth: point.type === 'city' ? 50 : 30,
        transform: 'translateY(-10%) translateX(-10%)',
      }}
    />,
  );
}

export function createIcon(point: FlightPoint) {
  return L.divIcon({
    html: renderIcon(point),
    className: '',
  });
}

import { graphql, useStaticQuery } from 'gatsby';

export function useMap() {
  const imageData = useStaticQuery(graphql`
      query {
          file(absolutePath: {
              regex: "/\\/images\\/classic-map-2\\.jpg/"
          }) {
              childImageSharp {
                  fixed(width: 5000) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `);
  const { src: mapUrl, height: mapHeight, width: mapWidth } = imageData.file.childImageSharp.fixed;
  return { mapUrl, mapHeight, mapWidth };
}

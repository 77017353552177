import React, {
  RefObject, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression } from 'leaflet';
import {
  ImageOverlay, Map as RMap, Tooltip, Viewport,
} from 'react-leaflet';
import styled from 'styled-components';
import { Location } from '../../../types';
import { getCoords, getLocation } from '../utils/utils';
import PointMarker from './PointMarker';
import MapControls from './MapControls';
import MapPolyline from './MapPolyline';
import MapSettings from './MapSettings';
import { capitalize, fromPointsToMap } from '../../../shared/utils';
import { useDeepMemo } from '../../common/hooks';
import { useMap } from '../../common/hooks/flightmaster';
import { FlightPath, FlightPoint } from '../../../types/generated';

const isDev = process.env.NODE_ENV === 'development';

const StyledMap = styled.div`
  .leaflet-container {
    background-color: ${(props) => props.theme.secondaryDark};
  }

  .leaflet-zoom-animated {
    will-change: transform;
  }
`;

export interface MapProps {
  className?: string;
  paths: FlightPath[];
  points: FlightPoint[];
  innerRef: RefObject<any>;
}

export default function Map(props: MapProps) {
  const {
    className, paths = [],
    points = [],
    innerRef,
  } = props;
  const { mapUrl, mapHeight, mapWidth } = useMap();
  const [viewport, setViewport] = useState<Viewport>();
  const [currentLoc, setCurrentLoc] = useState<Location>();
  const [mouseCoords, setMouseCoord] = useState<string>();
  const waitingLoc = useRef<Location>();
  const mapRef = useRef<RMap>();

  const minZoom = -2.5;
  const centerPosition: [number, number] = [(mapHeight / 2) - 50, mapWidth / 2];
  const pointsMap = useDeepMemo(() => fromPointsToMap(points), [points]);

  useEffect(() => {
    if (isDev) {
      mapRef.current.leafletElement.addEventListener('mousemove', (event: any) => {
        const { latlng: { lng: xx, lat: yy } } = event;
        const { x, y } = getLocation(xx, yy, mapWidth, mapHeight);
        const coord = `${x.toFixed(3)}:${y.toFixed(3)}`;
        setMouseCoord(coord);
      });
    }
  }, []);

  function centreMap() {
    setViewport({ center: centerPosition, zoom: minZoom });
    setCurrentLoc(null);
  }

  function focusPath(path: FlightPath) {
    const [fromLoc1, toLoc1] = path.endpoints;
    const fromFlightPoint = pointsMap.get(fromLoc1);
    const toFlightPoint = pointsMap.get(toLoc1);
    const { x: x1, y: y1 } = getCoords(fromFlightPoint, mapWidth, mapHeight);
    const { x: x2, y: y2 } = getCoords(toFlightPoint, mapWidth, mapHeight);

    setViewport({ center: [(y1 + y2) / 2, (x1 + x2) / 2], zoom: 0 });
    setCurrentLoc(null);
  }

  function focusPoint(point: FlightPoint) {
    const { x, y } = getCoords(point, mapWidth, mapHeight);
    setViewport({ center: [y, x], zoom: 0 });
    setCurrentLoc(null);
    waitingLoc.current = point.location;
  }

  // export map functions to innerRef
  useImperativeHandle(innerRef, () => ({ centreMap, focusPath, focusPoint }));

  function onClickLocation(location: Location) {
    const point = pointsMap.get(location);
    focusPoint(point);
  }

  function onMoveEnd() {
    if (waitingLoc.current) {
      setCurrentLoc(waitingLoc.current);
    }
    waitingLoc.current = null;
  }

  const pointMarkers = useDeepMemo(() => points.map((point) => {
    const { x, y } = getCoords(point, mapWidth, mapHeight);
    return (
      <PointMarker
        key={point.location}
        point={point}
        position={[y, x]}
        showPopup={point.location === currentLoc}
        onClickLocation={onClickLocation}
      />
    );
  }), [points, currentLoc]);

  const pathLines = useDeepMemo(() => paths.map((path) => {
    const [fromLoc, toLoc] = path.endpoints;
    const fromFlightPoint = pointsMap.get(fromLoc);
    const toFlightPoint = pointsMap.get(toLoc);
    if (!fromFlightPoint || !toFlightPoint) {
      return null;
    }
    const { x: x1, y: y1 } = getCoords(fromFlightPoint, mapWidth, mapHeight);
    const { x: x2, y: y2 } = getCoords(toFlightPoint, mapWidth, mapHeight);
    const positions: LatLngExpression[] = [[y1, x1], [y2, x2]];
    const label = capitalize(path.label || path.transport);
    return (
      <MapPolyline
        positions={positions}
        weight={5}
        path={path}
        key={positions.toString()}
      >
        <Tooltip>{label} to <strong>{toLoc}</strong></Tooltip>
      </MapPolyline>
    );
  }), [paths]);

  return (
    <StyledMap>
      <RMap
        ref={mapRef}
        maxBounds={[[0, 0], [mapHeight, mapWidth]]}
        center={centerPosition}
        zoom={minZoom}
        minZoom={minZoom}
        maxZoom={1.5}
        zoomDelta={0.5}
        zoomSnap={0.5}
        className={className}
        crs={L.CRS.Simple}
        viewport={viewport}
        onMoveEnd={onMoveEnd}
        useFlyTo
      >
        <ImageOverlay
          attribution="Map © Blizzard Entertainment | Credit /u/snoxthefox"
          url={mapUrl}
          bounds={[[0, 0], [mapHeight, mapWidth]]}
        />
        {pathLines}
        {pointMarkers}
        <MapControls position="bottomleft">
          <MapSettings />
        </MapControls>
        <MapControls position="topright">
          {mouseCoords}
        </MapControls>
      </RMap>
    </StyledMap>
  );
}
